<template>
  <div
    v-if="
      route.query._storyblok_lang &&
      !store.state.locales[$getCountry()].list.includes(
        route.query._storyblok_lang === 'default' ? 'en' : route.query._storyblok_lang,
      )
    "
    class="flex h-96 items-center justify-center"
  >
    No country and language match
  </div>
  <div v-else-if="story" class="flex flex-col flex-grow">
    <component
      :is="story.content.component"
      v-if="story.content.component"
      :key="story.content._uid"
      :blok="story.content"
      class="flex flex-col flex-grow"
    />
  </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';
import { buildPageSchemaFromStoryblok } from '~/utils/schemas.js';
import type { ISbStoryData } from 'storyblok-js-client';

const store = useStore();
const route = useRoute();

const nuxtApp = useNuxtApp();
const { fetchStoryblokLocalizedPage, buildHeadMetaTagsFromStoryblokPage } =
  useStoryblokUtils();

const resolveRelations: string[] = [];
resolveRelations.push('ArticleItems.highLightedArticle');
resolveRelations.push('ArticlesSuggestion.suggestedArticles');

// From migration - computed properties
const sessionUser = computed(() => store.getters['users/sessionUser']);
const isAppleAnonymousEmail = computed(
  () => store.getters['users/isAppleAnonymousUser'],
);
const hasSessionUser = computed(() => store.getters['users/hasSessionUser']);

const story = ref<ISbStoryData | null>(null);

// This enables real
onMounted(() => {
  if (story.value) {
    useStoryblokBridge(
      story.value.id,
      (evStory) => {
        story.value = evStory;
      },
      { resolveRelations: resolveRelations }, // Bridge Options
    );
  }
});

const { data, error } = await useAsyncData(route.path, async () => {
  const links = store.getters['getStoryLinks'];

  return await fetchStoryblokLocalizedPage(links);
});

story.value = data.value;

watch(
  [story, hasSessionUser],
  () => {
    // Build head properties
    if (story.value) {
      const globalSchemaOrganization =
        store.getters['getGlobalBloks'].schemaOrganization;

      const { schemas, schemaListOfDangerouslyDisableSanitizersId } =
        buildPageSchemaFromStoryblok(
          globalSchemaOrganization,
          story.value.content.schema?.[0],
        );

      const availableLocales = store.state.locales[store.getters['getCountry']].list;
      const localeMetaTags = availableLocales
        .filter(
          (locale: keyof typeof isoLanguageCode) =>
            locale !== nuxtApp.$getDefaultLocale(),
        )
        .map((locale: keyof typeof isoLanguageCode) => {
          return {
            hid: `i18n-og-alt-${locale}`,
            property: 'og:locale:alternate',
            content: isoLanguageCode[locale],
          };
        });

      const baseHeadProps = buildHeadMetaTagsFromStoryblokPage(
        story.value,
        localeMetaTags,
      );

      const head = {
        ...baseHeadProps,
        script: schemas,
        __dangerouslyDisableSanitizersByTagID:
          schemaListOfDangerouslyDisableSanitizersId,
      };
      useHead(head);
    }

    // If storyblok has set Page "hide_nav" to true then set navigation hidden
    if (story.value?.content?.hide_nav === true) {
      store.dispatch('hideNav', true);
    } else {
      store.dispatch('hideNav', false);
    }

    if (story.value?.content?.dashboardNav === true) {
      store.dispatch('dashboardNav', true);
    } else {
      store.dispatch('dashboardNav', false);
    }
  },
  { immediate: true },
);

if (error.value) {
  console.error(error.value);
}

onBeforeMount(() => {
  if (!hasSessionUser.value && story.value?.content?.allowOnlyInSession) {
    // Redirect to login page and open SignInModal
    setOverriddenSuccessfulRedirect(nuxtApp.$localizedPath(route.path));
    const path = nuxtApp.$localizedPath(route.path);
    navigateTo(
      {
        path: '/',
        query: {
          login: 'open',
          redirectUrl: path,
        },
      },
      { external: true },
    );
  }
});

watch(
  sessionUser,
  () => {
    if (isAppleAnonymousEmail.value) {
      setSignInModal({
        isSignInModalOpen: true,
        isAgreementFlowNeeded: false,
      });
    }
  },
  { immediate: true },
);

function setSignInModal(payload: {
  isSignInModalOpen: boolean;
  isAgreementFlowNeeded: boolean;
}) {
  store.commit('session/SET_SIGN_IN_MODAL', payload);
}

function setOverriddenSuccessfulRedirect(path: string) {
  store.commit('session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT', path);
}
</script>
